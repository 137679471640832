<template>
  <p-form
    @submit="onSubmit"
    v-slot="{ invalid }"
  >
    <div class="c-row c-horizontal-center h-full">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <div class="c-d-flex ui-w-full ui-mb-4">
          <div class="c-d-flex ui-flex-1 c-text-h3">
            Cadastrar novo cartão
          </div>
        </div>

        <div class="c-row h-full">
          <div class="c-col-2 sm:c-col-6 md:c-col-6 lg:c-col-5">
            <div class="c-d-flex ui-flex-col ui-w-full">
              <validation-provider
                class="ui-mb-4"
                name="Número do cartão"
                v-slot="{ errors }"
                rules="required"
              >
                <p-input
                  v-model="newCard.number"
                  label="Número do cartão*"
                  mask="0000 0000 0000 0000"
                  :error-message="errors[0]"
                />
              </validation-provider>

              <validation-provider
                class="ui-mb-4"
                name="Nome"
                v-slot="{ errors }"
                rules="required|only_letters_and_space"
              >
                <p-input
                  v-model="newCard.name"
                  label="Insira o nome como no cartão*"
                  :error-message="errors[0]"
                />
              </validation-provider>

              <div class="c-d-flex">
                <validation-provider
                  class="ui-flex-1 ui-mr-2 ui-mb-4"
                  name="Validade"
                  v-slot="{ errors }"
                  rules="required|valid_date"
                >
                  <p-input
                    v-model="newCard.date"
                    label="Validade (MM/AA)*"
                    mask="00/00"
                    masked
                    :error-message="errors[0]"
                  />
                </validation-provider>

                <validation-provider
                  name="Código verificador"
                  v-slot="{ errors }"
                  rules="required|min:3"
                >
                  <p-input
                    v-model="newCard.cvv"
                    label="CVV*"
                    mask="0000"
                    :error-message="errors[0]"
                    class="ui-flex-1 ui-ml-2 ui-mb-4"
                  />
                </validation-provider>
              </div>

              <span class="ui-mb-4">
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="$env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY"
                  :load-recaptcha-script="true"
                  @verify="(response) => recaptchaToken = response"
                  @expired="recaptchaToken = ''"
                  @error="recaptchaToken = ''"
                />
              </span>
            </div>
          </div>
        </div>

        <separator />
      </div>
    </div>

    <div class="c-row c-horizontal-center">
      <!-- Botão Alterar -->
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <c-button
          data-cy="submitCreditCard"
          type="submit"
          :disabled="invalid || loadingSubmit || !captchaValid()"
          :loading="loadingSubmit"
          class="ui-w-full ui-mb-4 sm:ui-mb-0"
        >
          Salvar Alteração
        </c-button>
      </div>

      <!-- Botão Cancelar -->
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <router-link
          :to="{ name: 'dados-pagamento' }"
        >
          <c-button
            outline
            :disabled="loadingSubmit"
            class="ui-w-full"
          >
            Voltar sem salvar
          </c-button>
        </router-link>
      </div>
    </div>
  </p-form>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';
import PForm from '@/components/p-form';
import PInput from '@/components/p-input';
import Separator from '@/components/separator';
import VueRecaptcha from 'vue-recaptcha';
import { CButton } from '@estrategiahq/coruja-web-ui';
import { mapGetters } from 'vuex';
import { OrderStoreKeys } from '@/store/modules/order';
import { UserStoreKeys } from '@/store/modules/user';

extend('only_letters_and_space', {
  validate: (value) => {
    const invalidCharRegex = /[^a-z ]/i;
    if (invalidCharRegex.test(value)) {
      return 'Caracteres especiais não são permitidos';
    }
    return true;
  },
});

extend('valid_date', {
  validate: (value) => {
    const [month, year] = value.split('/');
    const currentYear = new Date().getFullYear().toString().substr(2, 4);
    if (month > 0 && month <= 12 && year >= currentYear) {
      return true;
    }
    return 'Validade inválida. Use o formato MM/AA';
  },
});

export default {
  name: 'CadastrarCartao',
  components: {
    ValidationProvider,
    PForm,
    PInput,
    VueRecaptcha,
    CButton,
    Separator,
  },
  data() {
    return {
      loadingSubmit: false,
      newCard: {
        number: '',
        date: '',
        name: '',
        cvv: '',
      },
      recaptchaToken: '',
    };
  },
  computed: {
    ...mapGetters({
      loggedUser: UserStoreKeys.GET_LOGGED_USER,
    }),
  },
  methods: {
    async onSubmit() {
      this.loadingSubmit = true;

      const payload = {
        card: {
          holder_name: this.newCard.name,
          holder_document: this.loggedUser.cpf,
          number: this.newCard.number,
          exp_month: parseInt(this.newCard.date.split('/')[0]),
          exp_year: parseInt(this.newCard.date.split('/')[1]),
          cvv: this.newCard.cvv,
        },
        type: 'card',
        token: this.recaptchaToken,
      };

      try {
        await this.$store.dispatch(OrderStoreKeys.ACTION_CREATE_CREDIT_CARD, payload);
        this.$router.push({ name: 'dados-pagamento' });
        this.$toast.show({ type: 'success', text: 'Cartão cadastrado com sucesso' });
      } catch {
        this.$toast.show({ type: 'error', text: 'Houve um erro ao cadastrar o cartão, verifique os dados e tente novamente' });
      }

      this.loadingSubmit = false;
    },
    captchaValid () {
      if (this.$env.VUE_APP_NEW_CARD_RECAPTCHA_ENABLED === 'true') {
        return true;
      }

      return this.recaptchaToken !== '';
    },
  },
};
</script>
